@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Bold.woff2") format("woff2"),
    url("../fonts/Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Medium.woff2") format("woff2"),
    url("../fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Light.woff2") format("woff2"),
    url("../fonts/Inter-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-SemiBold.woff2") format("woff2"),
    url("../fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --primaryColor: #10a790;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --greyColor: #8a92a6;
  --greyColor2: #e2e8f0;
  --dangerColor: #fd2b2b;
  --thin-primaryColor: #10a7901a;
  --thin-greyColor: #f8fafc;
  --shadow-primaryColor: #10a79033;
  --shadow-blueColor: #3b82f61f;
  --hover-primaryColor: #0d8e7e;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  font-family: "Inter", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
li,
strong,
b,
label,
input,
button,
div {
  font-family: "Inter", sans-serif !important;
}

a:focus-visible {
  outline: 0 !important;
}

h2 {
  font-size: 32px;
  line-height: 40px;
}

h3 {
  font-size: 24px;
  line-height: 32px;
}

h4 {
  font-size: 20px;
  line-height: 26px;
}

h5 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.btn {
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  height: 50px;
  border: 1px solid !important;
  border-radius: 5px !important;
}

.btn-primary {
  color: var(--whiteColor) !important;
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
  transition: all 0.3s;
}

.btn-primary:hover {
  background-color: var(--hover-primaryColor) !important;
  border-color: var(--hover-primaryColor) !important;
  transition: all 0.3s;
}

.btn-outlined {
  color: var(--blackColor) !important;
  background-color: transparent !important;
  border-color: var(--blackColor) !important;
  transition: all 0.3s;
}

.btn-outlined:hover {
  background-color: var(--primaryColor) !important;
  color: var(--whiteColor) !important;
  border-color: var(--primaryColor) !important;
  transition: all 0.3s;
}

.btn-primary svg {
  color: var(--whiteColor);
}

.btn-primary .btn-icon-left {
  margin-right: 8px;
}

.btn-text {
  border: none !important;
  color: var(--blackColor) !important;
}

.btn-text svg {
  color: var(--darkColor) !important;
  font-size: 16px;
  margin-left: 4px;
  margin-right: 11px;
}

.btn-text:hover {
  background-color: transparent !important;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.MuiSvgIcon-root {
  color: var(--blackColor);
}

.MuiFormHelperText-root {
  margin: 4px 0 0 0 !important;
  color: var(--dangerColor) !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.MuiInputLabel-root {
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 400;
  color: var(--blackColor) !important;
  position: static !important;
  transform: unset !important;
  margin-bottom: 4px;
}

.Mui-disabled {
  opacity: 0.5;
}

.MuiMenu-paper .MuiMenuItem-root:hover,
.MuiMenu-paper .MuiMenuItem-root.Mui-selected {
  background-color: var(--primaryColor) !important;
  color: var(--whiteColor);
}

/****DatePicker Css****/
.MuiPickersDay-root.Mui-selected,
.MuiPickersYear-yearButton.Mui-selected {
  background-color: var(--primaryColor) !important;
}

.MuiPickersLayout-actionBar button {
  color: var(--primaryColor) !important;
}

/****TextField****/
.CTextField {
  margin-top: 10px;
}

.CTextField input::placeholder {
  color: var(--greyColor);
  opacity: 1;
}

.CTextField legend {
  display: none !important;
}

.CTextField fieldset {
  top: 0 !important;
  border-color: var(--greyColor) !important;
}

.CTextField input {
  padding: 13.5px !important;
  font-size: 16px;
  line-height: 22px;
  color: var(--blackColor);
}

.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--primaryColor) !important;
}

/****Progress****/
.CircularProgressbar .CircularProgressbar-trail {
  stroke: var(--greyColor2) !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: var(--primaryColor) !important;
}

.CircularProgressbar-text {
  font-weight: 600 !important;
  fill: var(--primaryColor) !important;
  font-size: 18px !important;
}

/****Dropdown****/
.MuiPopover-paper.MuiMenu-paper {
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor);
  border-radius: 15px;
}

.dropdown-items ul li {
  color: var(--blackColor);
  font-size: 16px;
  line-height: 22px;
  min-height: auto;
}

.dropdown-items ul li:hover {
  background-color: var(--greyColor2);
}

.dropdown-btn {
  color: var(--blackColor) !important;
  text-transform: unset !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
}

.dropdown-btn:hover {
  background-color: transparent !important;
}

.MuiMenuItem-root .MuiTouchRipple-root {
  display: none !important;
}

/****Loader****/
.CLoader {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  /* display: none; */
}

.CLoader svg {
  color: var(--primaryColor);
}

.MuiCircularProgress-root {
  position: relative;
  z-index: 100;
}

.CLoader:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--blackColor);
  opacity: 0.5;
  z-index: 1;
}

/***BreadCrumb****/
.MuiBreadcrumbs-li div {
  font-size: 16px;
  line-height: 22px;
  color: var(--blackColor);
}

.MuiBreadcrumbs-li:last-child div {
  color: var(--greyColor);
}

.MuiBreadcrumbs-separator {
  margin: 0 4px !important;
}

.CDatepicker legend,
.CSelect legend {
  display: none;
}

.CDatepicker .MuiButtonBase-root:hover {
  background-color: transparent !important;
}

.CDatepicker .MuiButtonBase-root .MuiTouchRipple-root {
  display: none !important;
}

.CDatepicker input {
  padding: 13.5px !important;
}

.CDatepicker fieldset,
.CSelect fieldset {
  top: 0 !important;
}

.MuiSelect-select {
  padding: 13.5px 32px 13.5px 13.5px !important;
}

.CDatepicker svg,
.CDatepicker label,
.CSelect svg,
.CSelect label {
  color: var(--blackColor);
}

/**Tostify Css**/
.Toastify div,
.Toastify svg {
  color: var(--blackColor) !important;
}

/****Login Page Css****/
.auth-page {
  display: flex;
  align-items: center;
}

.auth-page h2 {
  margin: 64px 0 32px 0;
}

.auth-page .col-left {
  height: 100vh;
  width: 55%;
  overflow-y: hidden;
  padding: 16px;
}

.auth-page .col-left img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.auth-page .col-right {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth-page .col-right img {
  width: 100%;
  max-width: 200px;
  height: 120px;
  object-fit: contain;
}

.auth-page .col-right .login-form {
  max-width: 440px;
}

.login-form .CTextField {
  margin-top: 20px;
}

.login-form .CCheckbox svg,
.login-form .CCheckbox {
  color: var(--greyColor);
}

.login-form .CCheckbox .MuiCheckbox-root.Mui-checked svg {
  color: var(--blackColor);
}

.login-form .btn-primary {
  margin-top: 32px;
}

/*****Header***/
.header-content {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--whiteColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 60px;
  box-shadow: 0px 4px 17.6px 0px #0000001a;
}

.header-content .header-logo {
  display: flex;
  align-items: center;
}

.header-content img {
  width: 172px;
}

.header-content .header-search-field {
  width: 50%;
  display: flex;
  justify-content: end;
}

.header-content .header-search-field .MuiFormControl-root {
  max-width: 450px;
}

.header-content .header-middle {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 300px);
  align-items: center;
}

.header-content .header-profile .MuiButtonBase-root {
  width: 50px !important;
  min-width: unset !important;
  height: 50px !important;
  border-radius: 50%;
  background-color: var(--greyColor) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.header-content .header-profile svg {
  color: var(--whiteColor);
}

/**Contract page***/

.main-content {
  background-color: var(--thin-greyColor);
  min-height: 100vh;
  padding: 40px 40px 40px 280px;
  /* position: relative; */
}

.card-box {
  border-radius: 15px;
  padding: 24px;
  background-color: var(--whiteColor);
}

.contract-page {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  height: calc(100vh - 136px);
}

.contract-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.contract-header img {
  width: 114px;
  object-fit: contain;
}

.contract-header h5 {
  color: var(--blackColor);
  text-transform: uppercase;
  font-weight: bold;
}

.contract-body .contract-fields {
  display: flex;
  align-items: end;
  gap: 6px;
  width: 100%;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.contract-body .contract-fields .field-text {
  min-height: 42px;
  border: 1px solid var(--greyColor);
  width: 100%;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  white-space: normal;
  padding: 4px;
}

.contract-body .contract-fields p {
  font-weight: 600;
  color: var(--blackColor);
  white-space: nowrap;
}

.contract-body .contract-fields span {
  white-space: nowrap;
}

.contract-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.contract-body .contract-fields .top-fields {
  width: calc(70% - 4px);
  display: flex;
  align-items: end;
  gap: 6px;
}

.contract-body .contract-fields .end-text {
  width: calc(30% - 4px);
}

.contract-body {
  margin: 8px 0;
  padding: 8px 0;
  height: calc(100% - 130px);
  overflow-y: auto;
}

.contract-body p,
.contract-body span,
.contract-body li {
  font-size: 14px;
  line-height: 20px;
}

.contract-body ul {
  padding: 12px 0 12px 48px;
}

.contract-body ul li {
  list-style: decimal;
  margin: 16px 0;
}

.contract-body .contract-fields .bottom-fields {
  width: calc(50% - 8px);
  margin-top: 16px;
}

.contract-body .contract-fields .signature-fields .field-text {
  min-height: 85px;
}

.contract-body .contract-fields .date-fields {
  display: flex;
  justify-content: end;
}

.contract-body .contract-fields .date-fields div {
  width: 100%;
  max-width: 180px;
}

/****Home***/
.home-content {
  padding: 24px 0 !important;
}

.user-name {
  display: flex;
  align-items: center;
}

.user-name .user-letter {
  border: 1px solid var(--blackColor);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 32px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  overflow: hidden;
}

.card {
  padding: 16px;
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor);
  border: 1px solid var(--greyColor2);
  border-radius: 15px;
  transition: all 0.3s;
}

.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.card h5 {
  color: var(--blackColor);
  padding: 16px 0;
}

.card .btn {
  font-size: 14px !important;
  line-height: 20px !important;
  height: 42px;
  min-width: 120px;
  margin-top: 8px;
}

.learning-card .card:hover {
  border-color: var(--primaryColor);
  box-shadow: 0px 4px 34px 0px var(--shadow-primaryColor);
  transition: all 0.3s;
}

.learning-card .card:hover .btn-outlined {
  background-color: var(--primaryColor) !important;
  color: var(--whiteColor) !important;
  border-color: var(--primaryColor) !important;
}

.learning-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin-top: 24px;
}

.learning-card .card {
  width: calc(100% / 4 - 18px);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.home-progress-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 24px;
  padding: 24px;
  gap: 12px;
}

.home-progress-content .progress-field {
  width: calc(100% / 3 - 8px);
  position: relative;
}

.home-progress-content .progress-field:first-child:after {
  content: "";
  background-color: var(--greyColor);
  position: absolute;
  right: 50px;
  top: -25px;
  height: calc(100% + 50px);
  width: 1px;
}

.home-progress-content .progress-field:first-child {
  padding-right: 60px;
}

.home-progress-content .circle-progress {
  display: flex;
  align-items: center;
  gap: 12px;
}

.home-progress-content .progress-field span {
  font-size: 14px;
  line-height: 20px;
  color: var(--blackColor);
}

.home-progress-content .circleProgress {
  width: 120px;
  height: 120px;
}

/*****Module View*****/
.breadcrumb-view {
  display: flex;
  align-items: center;
  gap: 12px;
}

.breadcrumb-view .btn {
  padding: 0;
}

.breadcrumb-view .btn svg {
  font-size: 32px;
}

.module-view .card-box {
  padding: 24px;
  margin-top: 24px;
  border: 1px solid var(--greyColor2);
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor);
}

/*****Sidebar****/
.sidebar {
  background-color: var(--whiteColor);
  width: 240px;
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: 0px 4px 17.6px 0px #0000001a;
  height: 100vh;
  padding: 24px 0px;
}

.sidebar .sidebar-logo {
  text-align: center;
}

.sidebar .sidebar-logo img {
  width: 100%;
  max-width: 172px;
  margin: auto;
  max-height: 100px;
  object-fit: contain;
}

.sidebar .MuiList-root {
  margin-top: 24px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}

.sidebar .MuiListItem-root {
  padding: 4px 0 !important;
  flex-direction: column;
}

.sidebar .MuiListItem-root svg {
  min-width: auto !important;
  margin-right: 12px;
  width: 20px;
  height: 20px;
}

.sidebar .MuiListItem-root a {
  text-decoration: none !important;
  padding: 12px 20px !important;
  display: flex;
  align-items: center;
  color: var(--blackColor);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  width: 100%;
}

.sidebar .MuiListItem-root a.active {
  background-color: var(--thin-primaryColor);
  font-weight: 600;
  color: var(--primaryColor);
}

.sidebar .MuiListItem-root a.active svg path {
  fill: var(--primaryColor);
}

.sidebar .sidebar-profile {
  width: 36px !important;
  min-width: unset !important;
  height: 36px !important;
  border-radius: 50%;
  background-color: var(--greyColor) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-right: 8px;
}

.sidebar .sidebar-profile svg {
  margin-right: 0;
  color: var(--whiteColor);
  font-size: 20px;
}

.bottom-sidebar-menu {
  position: absolute !important;
  bottom: 20px;
  width: 100%;
  padding: 0 !important;
}

/* .bottom-sidebar-menu:before {
  content: '';
  position: absolute;
  top: 0;
  left: 20px;
  width: calc(100% - 40px);
  background-color: var(--greyColor);
  height: 1px;
} */
.bottom-sidebar-menu .logout-btn {
  width: calc(100% - 40px) !important;
  border-radius: 5px;
  justify-content: center;
  font-weight: 600;
}

.bottom-sidebar-menu .btn-primary svg path {
  fill: var(--whiteColor) !important;
}

.bottom-sidebar-menu .logout-btn.active svg {
  color: var(--whiteColor) !important;
}

.sub-menu {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.3s ease-out, opacity 0.3s ease-out;
}

.sub-menu.open {
  height: auto;
  opacity: 1;
  width: 100%;
  padding-left: 32px;
}

.submenu-icon {
  position: absolute;
  right: 6px;
  top: 14px;
}

.sub-menu .MuiListItem-root a.active {
  background-color: transparent !important;
}

.sub-menu .MuiListItem-root {
  padding: 0 !important;
}

.submenu-icon svg {
  margin-right: 0 !important;
}

.sidebar .MuiList-root .sub-menu ul {
  margin-top: 10px;
}

/****report***/
.report-content h2 {
  margin-bottom: 16px;
  color: var(--blackColor);
}

.report-content .card-box {
  padding: 24px;
  margin-top: 24px;
  border: 1px solid var(--greyColor2);
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor);
  max-width: 100%;
}

.report-content .card-box .CDatepicker .MuiFormControl-root {
  width: 100%;
}

.CSelect label {
  color: var(--blackColor);
}

.report-table-filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
}

.report-table-filter .report-table-left,
.report-table-filter .report-table-right {
  width: calc(50% - 8px);
}

.report-table-filter .report-table-left .date-range-fields,
.report-table-filter .report-table-right {
  display: flex;
  align-items: end;
  gap: 12px;
}

.report-table-filter .report-table-right {
  justify-content: end;
}

.report-table-filter .CDatepicker {
  margin-top: 0;
}

.report-table-filter .CDatepicker .MuiInputBase-root {
  width: 200px;
}

.report-table-right .MuiFormControl-root {
  width: 300px;
}

.report-table-filter .report-table-left p {
  align-self: center;
}

.report-table-filter .report-table-right .btn-primary {
  min-width: 150px;
}

.date-range-label {
  width: 100%;
  margin-bottom: 4px;
}

/***Data grid Css***/

.table-box,
.MuiDataGrid-root {
  min-height: 250px !important;
}

.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader {
  background-color: var(--greyColor2);
  color: var(--blackColor);
}

.MuiDataGrid-columnHeader {
  min-height: unset !important;
  height: 48px !important;
}

.MuiDataGrid-cell {
  height: auto !important;
  min-height: 48px;
  display: flex;
  align-items: center;
  color: var(--blackColor);
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-footerContainer,
.MuiDataGrid-row,
.MuiDataGrid-cell,
.MuiDataGrid-filler div,
.MuiDataGrid-root {
  --rowBorderColor: var(--greyColor2) !important;
  border-color: var(--greyColor2) !important;
}

.MuiDataGrid-withBorderColor:focus,
.MuiDataGrid-withBorderColor,
.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
  outline: 0 !important;
  outline-offset: 0px !important;
}

.MuiDataGrid-cell div {
  color: var(--blackColor) !important;
}

.MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--borderTop {
  width: 0 !important;
  min-width: 0 !important;
  display: none !important;
}

.MuiDataGrid-scrollbarFiller {
  background-color: var(--greyColor2);
}

.MuiDataGrid-virtualScroller {
  height: calc(100vh - 341px) !important;
}

.MuiDataGrid-overlay {
  font-weight: 600;
  color: var(--blackColor) !important;
}

.MuiDataGrid-root {
  border-radius: 10px !important;
  overflow: hidden;
}

.MuiDataGrid-root .MuiDataGrid-row:hover {
  background-color: transparent !important;
}

.MuiTablePagination-actions button {
  color: var(--blackColor) !important;
}

.MuiTablePagination-actions button.Mui-disabled {
  color: var(--blackColor) !important;
  opacity: 0.5 !important;
}

.MuiTablePagination-input .MuiInputBase-input {
  border-radius: 5px;
  border: 1px solid var(--greyColor2);
  padding: 8px 32px 8px 8px !important;
}

.MuiTablePagination-input .MuiInputBase-input:focus {
  background-color: transparent !important;
  border-radius: 5px !important;
}

.MuiTablePagination-menuItem {
  font-size: 14px !important;
  line-height: 20px !important;
}

/*****404 css*****/
.page-not-found {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--thin-greyColor);
  overflow-y: hidden;
  text-align: center;
  padding: 24px;
}

.page-not-found .page-not-img {
  width: 100%;
  max-width: 450px;
  margin: 24px 0;
}

.page-not-found .logo-img {
  max-width: 172px;
  margin-bottom: 24px;
}

.page-not-found p {
  margin: 16px 0;
  max-width: 400px;
}

.page-not-found .btn-primary {
  min-width: 190px;
  margin-top: 16px;
}

/****Company List****/
.company-table-logo {
  width: 60px;
  height: 100%;
}

.company-table-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.MuiDataGrid-cell .btn-text {
  min-width: auto !important;
}

.MuiDataGrid-cell .btn-text svg {
  font-size: 24px;
  margin: 0;
}

.company-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-heading h2 {
  margin-bottom: 0;
}

.company-heading .btn-primary {
  min-width: 180px;
}

.company-heading .btn-primary svg {
  margin-right: 8px;
  font-size: 22px;
}

.setting-content .card-box {
  padding: 24px;
  margin-top: 24px;
  border: 1px solid var(--greyColor2);
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor);
  max-width: 100%;
  max-width: 50%;
}

.company-sec .breadcrumb-back {
  display: flex;
  align-items: center;
}

.company-sec .breadcrumb-back button {
  padding: 0 !important;
  height: auto !important;
  min-width: auto !important;
  margin-right: 16px;
}

.company-sec .breadcrumb-back button svg {
  font-size: 40px;
  margin: 0;
}

.company-form .MuiFormControl-root {
  margin-bottom: 16px;
}

.company-info-add {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 15px;
}

.company-info-add .upload-fields {
  width: calc(50% - 8px);
}

.company-info-add .upload-fields label {
  margin-bottom: 4px;
  display: block;
}

.company-table .MuiDataGrid-virtualScroller {
  height: calc(100vh - 260px) !important;
}

.upload-input {
  border: 1px dashed var(--greyColor);
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--thin-greyColor);
  height: 140px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.upload-input p {
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--blackColor) !important;
}

.upload-input:hover {
  border-color: var(--primaryColor);
}

.company-details-update {
  margin-top: 20px;
  text-align: end;
}

.company-details-update button {
  min-width: 130px;
}

.uploaded-preview {
  position: relative;
  border: 1px solid var(--greyColor2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  min-height: 140px;
}

.uploaded-preview .MuiIconButton-root {
  background-color: var(--greyColor2);
  width: 24px;
  height: 24px;
  position: absolute;
  right: 8px;
  top: 8px;
}

.uploaded-preview .MuiIconButton-root svg {
  font-size: 16px;
}

.uploaded-preview .MuiIconButton-root:hover {
  background-color: var(--primaryColor);
}

.uploaded-preview .MuiIconButton-root:hover svg {
  color: var(--whiteColor);
}

.uploaded-preview img {
  width: 100%;
  max-width: 200px;
  height: 122px;
  object-fit: contain;
}

.uploaded-preview a {
  color: var(--blackColor) !important;
}

/****CSV*****/
.upload-csv-content .card-box {
  width: 50%;
}

.upload-csv-content .upload-csv-btn {
  text-align: right;
  margin-top: 24px;
}

.upload-csv-content .upload-csv-btn button {
  min-width: 130px;
}

.dropzone,
.csv-uploaded {
  text-align: center;
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--thin-greyColor);
  border: 1px dashed var(--greyColor);
  border-radius: 5px;
  padding: 24px;
  position: relative;
}

.dropzone p {
  font-size: 14px;
  line-height: 20px;
}

.csv-uploaded button.btn-text {
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: var(--greyColor2);
  border-radius: 50% !important;
  width: 24px;
  height: 24px;
  min-width: unset !important;
}

.csv-uploaded p {
  font-size: 14px;
  line-height: 20px;
}

.csv-uploaded button.btn-text:hover {
  background-color: var(--primaryColor) !important;
}

.csv-uploaded button.btn-text svg {
  margin: 0;
  font-size: 16px;
}

.csv-uploaded button.btn-text:hover svg {
  color: var(--whiteColor) !important;
}

.dashboard-welcome-title {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: calc(100vh - 157px); */
  font-size: 2.2rem;
  font-weight: 500;
  margin-top: 50px;
  /* color: #bac1d3; */
}