@media (min-width: 600px) {
  .MuiContainer-root {
    max-width: 100% !important;
  }
}
@media (min-width: 900px) {
  .MuiContainer-root {
    max-width: 100% !important;
  }
}
@media (min-width: 900px) {
  .MuiContainer-root {
    max-width: 100% !important;
  }
}
@media (min-width: 1366px) {
  .MuiContainer-root {
    max-width: 1300px !important;
  }
}
@media (min-width: 1536px) {
  .MuiContainer-root {
    max-width: 1500px !important;
  }
}
@media (min-width: 992px) {
  .mobile-header,
  .delete-sidebar {
    display: none;
  }
}

@media (max-width: 1450px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
  h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .main-content {
    padding: 24px 24px 24px 264px;
  }
  .auth-page h2 {
    margin: 32px 0 16px;
  }
  .login-form .btn-primary {
    margin-top: 24px;
  }
  .auth-page .col-right .login-form {
    max-width: 400px;
    padding: 16px;
  }
  .auth-page .col-right img {
    max-width: 240px;
  }
  .table-box {
    overflow-x: auto;
  }
  .MuiDataGrid-virtualScroller {
    height: calc(100vh - 305px) !important;
  }
  .MuiDataGrid-root {
    overflow-x: auto !important;
  }
  .setting-content .card-box {
    max-width: 70%;
  }
  .assignment-table .MuiDataGrid-root{
    width: 1150px !important;
    overflow-x: auto !important;
  }
  .assignment-table .MuiDataGrid-root{
     white-space: normal !important;
     text-overflow: unset !important;
     line-height: auto !important;
  }
}
@media (max-width: 1366px) {
  .report-table-filter .CDatepicker .MuiInputBase-root {
    width: 170px;
  }
  .report-table-right .MuiFormControl-root {
    width: 220px;
  }
  .report-table-filter .report-table-right .btn-primary {
    min-width: 130px;
  }
  .MuiDataGrid-root {
    overflow-x: auto !important;
    width: 100%;
  }
  
}
@media (max-width: 1200px) {
  .setting-content .card-box,
  .upload-csv-content .card-box {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  h3 {
    font-size: 20px;
    line-height: 26px;
  }
  h5 {
    font-size: 16px;
    line-height: 22px;
  }
  p,
  .dropdown-btn,
  .btn,
  .dropdown-items ul li,
  .MuiFormControlLabel-label,
  .module-accordion .MuiAccordionDetails-root .module-task li,
  .MuiInputLabel-root,
  a,
  .profile-content .profile-upload span,
  .CTextField input,
  label {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .btn {
    height: 42px;
  }
  .login-form .CTextField {
    margin-top: 12px;
  }
  .login-form .btn-primary {
    margin-top: 16px;
  }
  .sidebar {
    left: -240px;
    z-index: 1000;
    transition: linear 0.2s;
  }
  .sidebar-open.sidebar {
    left: 0px;
    z-index: 100000;
    transition: linear 0.2s;
  }
  .main-content {
    padding: 16px;
    min-height: calc(100vh - 77px);
  }
  .header-content.mobile-header {
    padding: 12px 16px;
    position: relative;
    justify-content: center;
  }
  .header-content img,
  .sidebar .sidebar-logo img {
    width: 150px;
  }
  .header-content img {
    max-height: 53px;
    object-fit: contain;
  }
  body.sidebar-open {
    overflow-y: hidden;
  }
  body.sidebar-open:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    background: var(--blackColor);
    opacity: 0.5;
    z-index: 100;
    left: 0px;
    top: 0;
    transition: all 0.5s;
  }
  .mobile-toggle {
    position: absolute;
    left: 16px;
    top: calc(50% - 8px);
  }
  .delete-sidebar {
    position: absolute;
    right: -35px;
    z-index: 1000;
    background-color: var(--primaryColor);
    width: 35px;
    height: 35px;
    display: none;
    align-items: center;
    justify-content: center;
  }
  .delete-sidebar svg {
    color: var(--whiteColor);
  }
  .sidebar-open .delete-sidebar {
    display: flex;
  }
  .CDatepicker input {
    padding: 11px !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
  ::placeholder {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .MuiSelect-select {
    padding: 12px 32px 12px 13.5px !important;
    font-size: 14px !important;
  }
  .CTextarea .MuiInputBase-root {
    padding: 12px 16px !important;
    font-size: 14px !important;
  }
  .MuiMenu-paper .MuiMenuItem-root {
    font-size: 14px !important;
    line-height: 20px !important;
    height: 45px !important;
    min-height: auto;
  }
  .MuiDataGrid-virtualScroller {
    height: calc(100vh - 355px) !important;
  }
  .report-table-right .CSelect .MuiSelect-select {
    padding: 9.5px !important;
    padding-right: 32px !important;
  }
  .report-table-filter .report-table-left .date-range-fields,
  .report-table-filter .report-table-right {
    gap: 8px;
  }
  .report-table-filter .CDatepicker .MuiInputBase-root {
    width: 150px;
  }
  .sidebar {
    width: 280px;
    left: -280px;
  }
  .company-sec .breadcrumb-back button svg {
    font-size: 32px;
  }
  .card-box,
  .report-content .card-box {
    padding: 16px;
  }
  .setting-content .card-box {
    padding: 16px;
    margin-top: 16px;
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 24px;
    line-height: 30px;
  }
  h5 {
    font-size: 16px;
    line-height: 22px;
  }

  .auth-page {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .auth-page .col-left {
    width: 100%;
  }
  .auth-page .col-right {
    background-color: var(--whiteColor);
    border-radius: 15px;
    position: absolute;
    padding: 32px 24px;
    width: calc(100% - 32px);
    max-width: 400px;
  }
  .auth-page h2 {
    margin: 16px 0;
  }
  .auth-page .col-right .login-form {
    padding: 0;
  }
  .auth-page .col-right img {
    max-width: 180px;
    height: 100px;
  }
  .learning-card {
    gap: 16px;
    margin-top: 16px;
  }
  .learning-card .card {
    width: calc(100% / 2 - 8px);
  }

  .report-table-filter .report-table-left,
  .report-table-filter .report-table-right {
    width: 100%;
    justify-content: start;
  }
  .report-table-filter .report-table-right,
  .report-content .card-box {
    margin-top: 16px;
  }
  .report-table-filter .CDatepicker {
    margin-top: 0;
    width: calc(50% - 15px);
  }
  .report-table-filter .report-table-left .date-range-fields,
  .report-table-filter .report-table-right {
    flex-wrap: wrap;
  }
  .report-table-filter .CDatepicker .MuiInputBase-root {
    width: 100%;
  }
  .report-table-right .MuiFormControl-root {
    width: 100%;
  }
  .auth-page .col-left {
    padding: 0;
  }
  .auth-page .col-left img {
    border-radius: 0;
  }
  .company-table .MuiDataGrid-root {
    overflow-x: auto !important;
    width: 768px;
  }
}

@media (max-width: 575px) {
  h3 {
    font-size: 18px;
    line-height: 24px;
  }

  .company-info-add {
    flex-wrap: wrap;
  }
  .company-info-add .upload-fields {
    width: 100%;
  }
  .page-not-found .page-not-img {
    max-width: 350px;
  }
}
@media (max-width: 400px) {
  .contract-body {
    height: calc(100% - 160px);
  }
  .page-not-found .page-not-img {
    max-width: 300px;
  }
}
